<template>
    <div>
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Agent
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Service
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Amount
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Deposited By
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Date / Time
                    </th>

                    <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                        Status
                    </th>

                    <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only"></span>
                    </th>
                </tr>
            </thead>
            <tbody v-if="deposits" class="bg-white divide-y divide-gray-200">
                <tr v-for="(deposit, i) in deposits.data" :key="i">
                    <td class="px-3 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ deposit.agent }}
                                </div>
                            </div>
                        </div>
                    </td>

                    <td class="px-3 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ deposit.account }}
                                </div>
                            </div>
                        </div>
                    </td>

                    <td class="px-3 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    GMD {{ deposit.amount }}
                                </div>
                            </div>
                        </div>
                    </td>

                    <td class="px-3 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ deposit.created_by }}
                                </div>
                            </div>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        {{ deposit.created_at }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <span
                            v-if="deposit.status === 'deposited'"
                            class="px-2 inline-flex text-xs leading-5 uppercase font-semibold rounded-full bg-green-100 text-green-800"
                        >
                            deposited
                        </span>

                        <span
                            v-else
                            class="px-2 inline-flex text-xs uppercase leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                        >
                            reversed
                        </span>
                    </td>

                    <td
                        class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                    <!-- v-if="
                    checkPermissionExist(
                        user.data,
                        'revise-collection'
                    ) -->

                        <button
                        v-if="deposit.status === 'deposited'"
                            @click="reverse(deposit)"
                            class="text-red-600 hover:text-red-500"
                        >
                            Reverse
                        </button>
                    </td>
                </tr>
                <!-- More people... -->
                <!--  -->
            </tbody>
        </table>

        <div class="border-t" v-if="deposits">
            <pagination :data="deposits" :midSize="2" @clicked="loadData" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import checkPermissionExist from '../../mixins/checkPermissionExist';
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        deposits: null,
    }),

    mixins: [checkPermissionExist],
    computed: mapGetters({
        user: 'auth/user',
    }),

    created() {
        var _this = this;
        _this.loadData(1);
    },

    methods: {
        reverse(deposit) {
            // navigator.geolocation.getCurrentPosition(setPosition);

            Swal.fire({
                title: 'Reverse Confirmation?',
                html: `Please enter the deposit of ${deposit.amount} deposited by ${deposit.created_by} for ${deposit.agent} on ${deposit.created_at}`,
                input: 'number',
                placeholder: 'Enter Amount',
                type: 'warning',
                // footer: 'fooo',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, i confirm!',
            }).then(result => {
                // console.log(result,"---REsult");
                const resultAmount = Number(result.value);
                // console.log("Amount", );
                // console.log("DAmount", deposit.amount);
                if (deposit.amount !== resultAmount) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Opp!',
                        text: 'You entered the wrong correct amount',
                    });

                    return false;
                }
                if (result.value) {
                    axios
                        .post(
                            `/deposit/reverse?type=chapman&depositId=${deposit.id}`
                        )
                        .then(response => {
                            console.log(response);

                            // let redirect = response.data;
                            // app.$router.push({
                            //   path: `/reservation` + "/" + redirect
                            // });
                            Swal.fire({
                                icon: 'success',
                                title: 'Deposit Successfully reversed!',
                                text: '',
                            });
                        })
                        .then(res => {
                            console.log(res);

                            setTimeout(() => {
                                // var _this = this;
                                this.loadData(1);
                            }, 1000);
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.show = false;
                            this.isLoading = false;
                            this.clearForm();
                            Swal.fire('Sorry!', `${error}`, 'error');
                        });
                }
            });
        },

        async loadData(pageNo, filter) {
            // Submit the form
            if (filter) {
                // filter = filter;
                this.searching = true;
            } else {
                filter = '';
                this.searching = false;
            }

            try {
                const { data } = await axios.get(
                    `/deposits?page=${pageNo}&filter=${filter}`
                );

                console.log(data);

                this.deposits = data;
                this.searching = false;

                // console.log("The current page is: ", this.agents.meta.current_page);

                return true;
            } catch (e) {
                console.log('Error');
                this.searching = false;
                return false;
            }
        },
    },
};
</script>
